<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="distributorName"
                    label="姓名"
                >
                    <el-input
                        v-model="queryFormModel.distributorName"
                        placeholder="请输入姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="distributorTel"
                    label="电话"
                >
                    <el-input
                        v-model="queryFormModel.distributorTel"
                        placeholder="请输入电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="distributorLevelId"
                    label="分销商等级"
                >
                    <el-select
                        v-model="queryFormModel.distributorLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in distributorLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="isAddWechat"
                    label="是否添加微信"
                >
                    <el-select
                        v-model="queryFormModel.isAddWechat"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="1"
                        />
                        <el-option
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <!--<el-form-item
            prop="isSalesman"
            label="是否业务员"
        >
            <el-select
                v-model="queryFormModel.isSalesman"
                placeholder="请选择"
            >
                <el-option
                    label="全部"
                    value=""
                />
                <el-option
                    label="是"
                    value="1"
                />
                <el-option
                    label="否"
                    value="0"
                />
            </el-select>
        </el-form-item>-->
                <el-form-item
                    prop="referenceName"
                    label="推荐人"
                >
                    <el-input
                        v-model="queryFormModel.referenceName"
                        placeholder="请输入推荐人姓名或电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="isDistributor"
                    label="是否分销商"
                >
                    <el-select
                        v-model="queryFormModel.isDistributor"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="1"
                        />
                        <el-option
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="approvalStatus"
                    label="审核状态"
                >
                    <dictionaries-picker
                        type="approvalStatus"
                        v-model="queryFormModel.approvalStatus"
                    />
                </el-form-item>
                <el-form-item
                    prop="joinTime"
                    label="加入时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.joinTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/dt/dtmg/distributor/edit')"
                    >
                        新增
                    </el-button>
                    <!--<el-button
              type="success"
              size="small"
              @click="onBatchSetSalesman"
          >
              批量分配业务员
          </el-button>-->
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="distributorName"
                    label="姓名"
                    min-width="100"
                />
                <el-table-column
                    prop="mobile"
                    label="电话"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.distributorCountryCode }}-{{ scope.row.distributorTel }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="wechatId"
                    label="微信号"
                    min-width="100"
                />
                <el-table-column
                    prop="distributorLevelName"
                    label="分销商等级"
                    min-width="100"
                />
                <el-table-column
                    label="推荐人"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <div>{{ scope.row.referenceDistributorName }}</div>
                        <div>{{ scope.row.referenceDistributorCountryCode }}-{{ scope.row.referenceDistributorTel }}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="waitCompleteDistributorOrderCount"
                    label="未完成订单数"
                    min-width="100"
                />
                <el-table-column
                    prop="completeDistributorOrderCount"
                    label="已完成订单数"
                    min-width="100"
                />
                <el-table-column
                    prop="cancelDistributorOrderCount"
                    label="已取消订单数"
                    min-width="100"
                />
                <el-table-column
                    prop="recommendCount"
                    label="推荐人数"
                    min-width="100"
                />
                <el-table-column
                    label="是否分销商"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isDistributor ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否添加微信"
                    width="95"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAddWechat"
                            @input="onIsAvailableInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <!--<el-table-column
            label="是否业务员"
            width="95"
        >
            <template slot-scope="scope">
                <el-switch
                    v-model="scope.row.isSalesman"
                    @input="onIsSalesmanInput($event,scope)"
                />
            </template>
        </el-table-column>
        <el-table-column
            prop="salesmanDistributorName"
            label="所属业务员"
            width="95"
        />-->
                <el-table-column
                    label="审核状态"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.approvalStatusName }}
                        <label v-if="scope.row.approvalStatus === 99">
                            ({{ scope.row.approvalRemark }})
                        </label>
                    </template>
                </el-table-column>
                <el-table-column
                    label="加入时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.joinTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="到期时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.dueTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="240"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/dt/dtmg/distributor/edit/' + scope.row.id)"
                            v-if="scope.row.approvalStatus !== 99"
                        >
                            {{ scope.row.approvalStatus === 10 ? '审核' : '编辑' }}
                        </el-button>
                        <!--<el-button
                type="text"
                size="small"
                v-if="scope.row.isSubordinate == 1"
                @click="findSubordinateDistributor(scope.row.id)"
            >查看下级
            </el-button>-->
                        <el-button
                            type="text"
                            size="small"
                            v-if="scope.row.isSubordinate == 1"
                            @click="$router.push(`/dt/dtmg/distributor/subordinate/${scope.row.id}`)"
                        >
                            查看下级
                        </el-button>
                        <!--<el-button
                type="text"
                size="small"
                v-if="scope.row.isSalesman == 1"
                @click="$router.push(`/dt/dtmg/distributor/salesman/${scope.row.id}`)"
            >
                查看业务层级
            </el-button>-->
                        <el-button
                            type="text"
                            size="small"
                            @click="onCancelDistributor(scope.row.id)"
                            v-if="scope.row.approvalStatus === 100"
                        >
                            取消分销商
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onGoodsForbidSell(scope.row)"
                            v-if="scope.row.approvalStatus === 100 && userData.shop.shopStoreType !== 99 && userData.shop.storeOpenMode === 2"
                        >
                            禁售商品
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/dt/dtmg/distributor/buyerVisitLogDetail/${scope.row.id}`)"
                            v-if="scope.row.approvalStatus === 100"
                        >
                            流量统计
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <el-dialog
            title="批量设置业务员"
            center
            width="600px"
            :visible.sync="setSalesmanDialog.isVisible"
            @closed="onSetSalesmanDialogCancel"
        >
            <el-form
                ref="setSalesmanForm"
                size="small"
                :model="setSalesmanDialog.formModel"
                :rules="setSalesmanDialog.formRules"
            >
                <el-form-item
                    prop="salesmanBuyerId"
                    label="业务员"
                    label-width="8em"
                >
                    <RemoteSelect
                        remote="/mb/distributor/data?isSalesman=1&isDistributor=1"
                        placeholder="请输入"
                        :props="{id:'id',name:'distributorName'}"
                        v-model="setSalesmanDialog.formModel.salesmanBuyerId"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSetSalesmanDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSetSalesmanDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!--分销商禁售-->
        <el-dialog
            title="分销商禁售"
            center
            width="1200px"
            :visible.sync="goodsForbidSellDialog.isVisible"
            @closed="onGoodsForbidSellCancel"
        >
            <!-- 内容 -->
            <div class="body">
                <el-form
                    ref="goodsForbidSellDialogQueryForm"
                    class="second-header npa-b"
                    size="small"
                    :inline="true"
                    :model="goodsForbidSellDialog.queryFormModel"
                >
                    <el-form-item
                        prop="goodsName"
                        label="商品名称"
                    >
                        <el-input
                            v-model="goodsForbidSellDialog.queryFormModel.goodsName"
                            placeholder="请输入商品名称"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="goodsSn"
                        label="商品货号"
                    >
                        <el-input
                            v-model="goodsForbidSellDialog.queryFormModel.goodsSn"
                            placeholder="请输入商品货号"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            @click="onGoodsForbidSellDialogQuery()"
                        >
                            查询
                        </el-button>
                        <el-button
                            type="success"
                            @click="onResetGoodsForbidSellDialog"
                        >
                            重置
                        </el-button>
                    </el-form-item>
                </el-form>

                <div
                    class="ma-b"
                >
                    <el-button-group>
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                            @click="onAddSelect()"
                        >
                            选择商品
                        </el-button>
                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="small"
                            @click="onDelete"
                            :disabled="!goodsForbidSellDialog.currentTableSelect.length"
                        >
                            删除
                        </el-button>
                    </el-button-group>
                </div>

                <el-table
                    stripe
                    border
                    size="small"
                    :data="goodsForbidSellDialog.tableData"
                    style="width: 100%"
                    @selection-change="onGoodsForbidSellDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="36"
                    />
                    <el-table-column
                        prop="goodsName"
                        label="商品名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        label="商品编号"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="45"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="onDeleteSelect(scope.row.id)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="goodsForbidSellDialog.pagination"
                        @input="onGoodsForbidSellDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onGoodsForbidSellCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="选择商品"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    label="商品编号"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsSn"
                        placeholder="请输入商品编号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="goodsId"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="36"
                    />
                    <el-table-column
                        prop="mainImageUrl"
                        label="商品图片"
                        width="135"
                    >
                        <template slot-scope="scope">
                            <image-list :data="scope.row.mainImageUrl"/>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsName"
                        label="商品名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        label="商品编号"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'Distributor',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                distributorName: '', // 姓名
                distributorTel: '', // 电话
                distributorLevelId: '',
                isJoinDistributor: '1',
                referenceId: '',
                isAddWechat: '',
                referenceName: '',
                joinTime: '',
                isDistributor: '',
                isSalesman: '',
                approvalStatus: '',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            // 分销等级列表
            distributorLevelList: [],
            setSalesmanDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    ids: '',
                    salesmanBuyerId: '',
                },
                // 表单校验规则
                formRules: {
                    estimateShippingTime: {
                        required: true,
                        message: '请输入预计发货时间',
                        trigger: 'blur',
                    },
                },
            },
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                    goodsSn: '', // 商品编号
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            goodsForbidSellDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    buyerId: '',
                    goodsName: '',
                    goodsSn: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                currentTableSelect: [],
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.Distributor.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onCancelDistributor(id) {
            this.$confirm('是否确定取消该分销商！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Mb.Distributor.cancelDistributor({ id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        initDistributorLevelList() {
            this.$api.Mb.DistributorLevel.select().then(json => {
                this.distributorLevelList = json.data.data;
            });
        },
        findSubordinateDistributor(referenceId) {
            this.queryFormModel.referenceId = referenceId;
            this.onQuery({ replace: false });
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Mb.Distributor.changeIsAddWechat({
                id: row.id,
                isAddWechat: row.isAddWechat,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isAddWechat = !row.isAddWechat;
                });
        },
        onIsSalesmanInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Mb.Distributor.changeIsSalesman({
                id: row.id,
                isSalesman: row.isSalesman,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isSalesman = !row.isSalesman;
                });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        setSalesman(ids) {
            this.setSalesmanDialog.formModel.ids = ids;
            this.setSalesmanDialog.isVisible = true;
        },
        onBatchSetSalesman() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择分销商',
                    type: 'error',
                });
                return;
            }
            this.setSalesman(ids.join(','));
        },
        onSetSalesmanDialogConfirm() {
            const { setSalesmanForm } = this.$refs;
            setSalesmanForm.validate().then(() => {
                this.$api.Mb.Distributor.batchSetSalesman({ ...this.setSalesmanDialog.formModel }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.setSalesmanDialog.isVisible = false;
                });
            });
        },
        onSetSalesmanDialogCancel() {
            const { setSalesmanForm } = this.$refs;
            setSalesmanForm.resetFields();
            const { formModel } = this.setSalesmanDialog;
            formModel.ids = '';
            this.setSalesmanDialog.isVisible = false;
        },
        // 分销商禁售
        onGoodsForbidSell(row) {
            this.goodsForbidSellDialog.queryFormModel.buyerId = row.id;
            this.$nextTick(() => {
                this.onGoodsForbidSellDialogQuery();
            });
            this.goodsForbidSellDialog.isVisible = true;
        },
        onGoodsForbidSellDialogQuery(pagination) {
            return this.$api.Gd.GoodsForbidSell.data({
                ...this.goodsForbidSellDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.goodsForbidSellDialog.tableData = res.data;
                this.goodsForbidSellDialog.pagination = res.pagination;
            });
        },
        onResetGoodsForbidSellDialog() {
            this.$refs.goodsForbidSellDialogQueryForm.resetFields();
        },
        onGoodsForbidSellDialogSelectionChange(val) {
            this.goodsForbidSellDialog.currentTableSelect = val;
        },
        onGoodsForbidSellCancel() {
            this.goodsForbidSellDialog.isVisible = false;
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Gd.Goods.selectByPage({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                goodsForbidSellBuyerIdNE: this.goodsForbidSellDialog.queryFormModel.buyerId,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            const ids = this.selectDialog.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择商品',
                    type: 'error',
                });
                return;
            }
            this.$api.Gd.GoodsForbidSell.save({
                goodsIds: ids.join(','),
                buyerIds: this.goodsForbidSellDialog.queryFormModel.buyerId,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onGoodsForbidSellDialogQuery(this.goodsForbidSellDialog.pagination);
                this.selectDialog.isVisible = false;
            });
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDelete() {
            const ids = this.goodsForbidSellDialog.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onDeleteSelect(ids.join(','));
        },
        onDeleteSelect(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Gd.GoodsForbidSell.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onGoodsForbidSellDialogQuery(this.goodsForbidSellDialog.pagination);
                });
            });
        },
    },
    created() {
        this.initDistributorLevelList();
    },
};
</script>

<style lang="scss">
</style>
