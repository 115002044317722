<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="levelName"
                    label="等级名称"
                >
                    <el-input
                        v-model="queryFormModel.levelName"
                        placeholder="请输入等级名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="状态"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="启用"
                            value="1"
                        />
                        <el-option
                            label="禁用"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="levelIcon"
                    label="等级图标"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.levelIconUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="levelCard"
                    label="等级卡片"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.levelCardUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="levelName"
                    label="等级名称"
                    min-width="100"
                />
                <el-table-column
                    prop="levelDesc"
                    label="等级描述"
                    min-width="100"
                />
                <el-table-column
                    prop="orderNum"
                    label="排序号"
                    min-width="100"
                />
                <el-table-column
                    prop="isAvailable"
                    label="是否可用"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isAvailable"
                            @input="onIsAvailableInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="isDefault"
                    label="是否默认"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.isDefault"
                            @input="onIsDefaultInput($event,scope)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    label="创建时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.insertTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="修改时间"
                    width="135"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="170"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onRecommendIncomeEdit(scope.row)"
                            v-if="userData.shop.distributorRegisterMode === 2"
                        >
                            推荐收益设置
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/dt/dtmg/distributorLevel/rightsDescEdit/${scope.row.id}`)"
                            v-if="userData.shop.distributorRegisterMode === 2"
                        >
                            代理装修
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push(`/dt/dtmg/distributorLevel/distributeIncomeDescEdit/${scope.row.id}`)"
                        >
                            规则说明
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="800px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="levelName"
                    label="等级名称"
                    label-width="9em"
                >
                    <el-input
                        v-model="addDialog.formModel.levelName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="levelIcon"
                    label="等级图标"
                    label-width="9em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog.formModel.levelIcon"
                    />
                </el-form-item>
                <el-form-item
                    prop="levelCard"
                    label="等级卡片"
                    label-width="9em"
                >
                    <upload
                        validate-md5
                        action="/rs/attachment/uploadFileLibrary"
                        :limit="1"
                        v-model="addDialog.formModel.levelCard"
                    />
                </el-form-item>
                <el-form-item
                    prop="levelDesc"
                    label="等级描述"
                    label-width="9em"
                >
                    <el-input
                        type="textarea"
                        v-model="addDialog.formModel.levelDesc"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderNum"
                    label="排序"
                    label-width="9em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        v-model.number="addDialog.formModel.orderNum"
                    />
                </el-form-item>
                <!--                <el-form-item
                    prop="freightRate"
                    label="运费打折系数"
                    label-width="9em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="4"
                        v-model.number="addDialog.formModel.freightRate"
                    />
                </el-form-item>-->
                <el-form-item
                    prop="distributionIncomeMode"
                    label="分销收益模式"
                    label-width="9em"
                    v-show="shopDistributionIncomeMode.length !== 0"
                >
                    <checkbox-group-andOr
                        :data="shopDistributionIncomeMode"
                        v-model="addDialog.formModel.distributionIncomeMode"
                    />
                </el-form-item>
                <el-form-item
                    prop="shareIncomeRate"
                    label="分享赚收益系数"
                    label-width="9em"
                    v-if="(addDialog.formModel.distributionIncomeMode & 1) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.shareIncomeRate"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopStoreType"
                    label="门店类型"
                    label-width="9em"
                    v-show="userData.shop.shopStoreType !== 99 && shopStoreTypeList.length !== 0"
                >
                    <checkbox-group-andOr
                        :data="shopStoreTypeList"
                        v-model="addDialog.formModel.shopStoreType"
                    />
                </el-form-item>
                <el-form-item
                    prop="openStoreFee"
                    label="开店费"
                    label-width="9em"
                    v-if="userData.shop.shopStoreType !== 99 && userData.shop.storeOpenMode === 2"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.openStoreFee"
                    />
                </el-form-item>
                <el-form-item
                    prop="openStoreBalance"
                    label="开店返零钱"
                    label-width="9em"
                    v-if="userData.shop.shopStoreType !== 99 && userData.shop.storeOpenMode === 2"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.openStoreBalance"
                    />
                </el-form-item>
                <el-form-item
                    prop="isOpenApply"
                    label="是否开放申请"
                    label-width="9em"
                    v-if="userData.shop.distributorRegisterMode === 2"
                >
                    <el-switch
                        v-model="addDialog.formModel.isOpenApply"
                    />
                </el-form-item>
                <el-form-item
                    prop="isGainGrowthValue"
                    label="是否获取成长值"
                    label-width="9em"
                    v-if="(userData.shop.buyerConfig & 1) != 0"
                >
                    <el-switch
                        v-model="addDialog.formModel.isGainGrowthValue"
                    />
                </el-form-item>
                <el-form-item
                    prop="isGrowthValueUpgrade"
                    label="是否可升级"
                    label-width="9em"
                    v-if="(userData.shop.buyerConfig & 1) != 0"
                >
                    <el-switch
                        v-model="addDialog.formModel.isGrowthValueUpgrade"
                    />
                </el-form-item>
                <el-form-item
                    prop="gradeGrowthValue"
                    label="等级成长值"
                    label-width="9em"
                    v-if="addDialog.formModel.isGrowthValueUpgrade && (userData.shop.buyerConfig & 1) != 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="0"
                        v-model.number="addDialog.formModel.gradeGrowthValue"
                    />
                    到达该成长值，可升级为该等级
                </el-form-item>
                <el-form-item
                    prop="growthValueRatio"
                    label="成长值获取比例"
                    label-width="9em"
                    v-if="addDialog.formModel.isGainGrowthValue && (userData.shop.buyerConfig & 1) != 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.growthValueRatio"
                    />
                    %
                </el-form-item>
                <el-form-item
                    prop="isDisableDistributionIncome"
                    label="是否下单无分销佣金"
                    label-width="9em"
                >
                    <el-switch
                        v-model="addDialog.formModel.isDisableDistributionIncome"
                    />
                </el-form-item>
                <!--                <el-form-item
                    prop="isGstFree"
                    label="是否减免GST"
                    label-width="9em"
                    v-if="userData.shop.goodsSendModes.split(',').includes('2')"
                >
                    <el-switch
                        v-model="addDialog.formModel.isGstFree"
                    />
                </el-form-item>-->
                <el-form-item
                    prop="distributorDiscount"
                    label="分销商折扣"
                    label-width="9em"
                    v-if="(userData.shop.buyerConfig & 16) != 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.distributorDiscount"
                    />
                    %
                </el-form-item>
                <el-form-item
                    prop="withdrawType"
                    label="提现类型"
                    label-width="9em"
                >
                    <checkbox-group-andOr
                        type="distributorWithdrawType"
                        v-model="addDialog.formModel.withdrawType"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否可用"
                    label-width="9em"
                >
                    <el-switch
                        v-model="addDialog.formModel.isAvailable"
                    />
                </el-form-item>
                <el-form-item
                    prop="isDefault"
                    label="是否默认"
                    label-width="9em"
                >
                    <el-switch
                        v-model="addDialog.formModel.isDefault"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="推荐收益设置"
            center
            width="700px"
            :visible.sync="recommendIncomeDialog.isVisible"
            @closed="onRecommendIncomeDialogCancel"
        >
            <el-form
                ref="recommendForm"
                size="small"
                :model="recommendIncomeDialog.formModel"
                :rules="recommendIncomeDialog.formRules"
            >
                <el-form-item
                    prop="agentFee"
                    label="代理费"
                    label-width="9em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0.01"
                        :precision="2"
                        v-model.number="recommendIncomeDialog.formModel.agentFee"
                    />
                </el-form-item>
                <el-form-item
                    prop="recommendIncomeLevel"
                    label="推荐收益级别"
                    label-width="9em"
                >
                    <dictionaries-picker
                        type="recommendIncomeLevel"
                        v-model="recommendIncomeDialog.formModel.recommendIncomeLevel"
                        @input="initRecommendIncomeList"
                    />
                </el-form-item>
                <el-form-item
                    prop="recommendIncome"
                    label="推荐收益设置"
                    label-width="9em"
                >
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="recommendIncomeDialog.recommendIncomeList"
                        style="width: 100%"
                    >
                        <el-table-column type="expand">
                            <template slot-scope="level">
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="level.row.list"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="name"
                                        label="推荐层级"
                                        width="120"
                                    />
                                    <el-table-column
                                        prop="recommendIncomeAmount"
                                        label="提成金额"
                                        min-width="220"
                                    >
                                        <template slot-scope="scope">
                                            <el-input-number
                                                controls-position="right"
                                                :min="0"
                                                :precision="2"
                                                v-model.number="scope.row.recommendIncomeAmount"
                                            />
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="distributorLevelName"
                            label="推荐级别"
                            min-width="120"
                        />
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="bestowDistributor"
                    label="赠送权益"
                    label-width="9em"
                >
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="recommendIncomeDialog.bestowDistributorList"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="levelName"
                            label="赠送级别"
                            min-width="120"
                        />
                        <el-table-column
                            prop="quantity"
                            label="赠送数量"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    v-model.number="scope.row.quantity"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="promoPrice"
                            label="优惠后价格"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    v-model.number="scope.row.promoPrice"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onRecommendIncomeDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onRecommendIncomeDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'DistributorLevel',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                levelName: '', // 等级名称
                isAvailable: '', // 是否可用
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    levelName: '',
                    levelDesc: '',
                    levelIcon: [],
                    levelCard: [],
                    fileBasePath: '',
                    orderNum: '',
                    isAvailable: true,
                    isDefault: false,
                    isOpenApply: false,
                    distributionIncomeMode: '',
                    shopStoreType: '',
                    freightRate: '1',
                    openStoreFee: '0',
                    openStoreBalance: '0',
                    isGainGrowthValue: false,
                    isGrowthValueUpgrade: false,
                    gradeGrowthValue: '0',
                    growthValueRatio: 100,
                    isDisableDistributionIncome: false,
                    isGstFree: false,
                    shareIncomeRate: 1,
                    distributorDiscount: 100,
                    withdrawType: '',
                },
                // 表单校验规则
                formRules: {
                    levelName: {
                        required: true,
                        message: '请输入等级名称',
                        trigger: 'blur',
                    },
                    recommendIncomeLevel: {
                        required: true,
                        message: '请选择推荐收益级别',
                    },
                    levelIcon: {
                        type: 'array',
                        required: true,
                        message: '请上传等级图片',
                    },
                    levelCard: {
                        type: 'array',
                        required: true,
                        message: '请上传等级卡片',
                    },
                    freightRate: {
                        required: true,
                        message: '请输入运费打折系数',
                        trigger: 'blur',
                    },
                    gradeGrowthValue: {
                        required: true,
                        message: '请输入等级成长值',
                        trigger: 'blur',
                    },
                    growthValueRatio: {
                        required: true,
                        message: '请输入成长值获取比例',
                        trigger: 'blur',
                    },
                    isGainGrowthValue: {
                        required: true,
                        message: '请输入',
                    },
                    isGrowthValueUpgrade: {
                        required: true,
                        message: '请输入',
                    },
                    isDisableDistributionIncome: {
                        required: true,
                        message: '请输入',
                    },
                    isGstFree: {
                        required: true,
                        message: '请输入',
                    },
                    shareIncomeRate: {
                        required: true,
                        message: '请输入分享赚收益系数',
                    },
                },
            },
            // 推荐收益设置
            recommendIncomeDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    agentFee: '',
                    recommendIncomeLevel: 1,
                    recommendIncome: '',
                    bestowDistributor: '',
                },
                // 表单校验规则
                formRules: {},
                recommendIncomeList: [],
                bestowDistributorList: [],
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            shopDistributionIncomeMode: [],
            distributorLevelList: [],
            shopStoreTypeList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Mb.DistributorLevel.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
            this.initRecommendIncomeList();
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Mb.DistributorLevel.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Mb.DistributorLevel.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Mb.DistributorLevel.save({
                    ...this.addDialog.formModel,
                    recommendIncome: JSON.stringify(this.addDialog.recommendIncomeList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onIsAvailableInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Mb.DistributorLevel.status({
                id: row.id,
                isAvailable: row.isAvailable,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isAvailable = !row.isAvailable;
                });
        },
        initShopDistributionIncomeMode() {
            this.$api.Sp.Shop.getShopAvailableDistributionIncomeMode({}).then(json => {
                const res = json.data.data;
                this.shopDistributionIncomeMode = res;
            });
        },
        initShopStoreType() {
            this.$api.Sp.Shop.getShopAvailableStoreType({}).then(json => {
                const res = json.data.data;
                this.shopStoreTypeList = res;
            });
        },
        initRecommendIncomeList() {
            const result = [];
            this.distributorLevelList.forEach(level => {
                let levelItem = {};
                let list = [];
                let isExitLevel = false;
                this.recommendIncomeDialog.recommendIncomeList.forEach(item => {
                    if (item.distributorLevelId === level.distributorLevelId) {
                        isExitLevel = true;
                        levelItem = item;
                        levelItem.distributorLevelName = level.levelName;
                        list = levelItem.list;
                    }
                });
                if (!isExitLevel) {
                    levelItem = {
                        distributorLevelId: level.distributorLevelId,
                        distributorLevelName: level.levelName,
                        list,
                    };
                }
                const newList = [];
                for (let num = 1; num <= this.recommendIncomeDialog.formModel.recommendIncomeLevel; num += 1) {
                    let isExit = false;
                    list.forEach(item => {
                        if (item.id === num) {
                            isExit = true;
                            newList.push(item);
                            if (+num === 1) {
                                item.name = '直属';
                            } else {
                                item.name = `${num - 1}级`;
                            }
                        }
                    });
                    if (!isExit) {
                        const levelIncomeRate = {};
                        levelIncomeRate.id = num;
                        if (+num === 1) {
                            levelIncomeRate.name = '直属';
                        } else {
                            levelIncomeRate.name = `${num - 1}级`;
                        }
                        newList.push(levelIncomeRate);
                    }
                }
                newList.sort((a, b) => a.id - b.id);
                levelItem.list = newList;
                result.push(levelItem);
            });

            this.recommendIncomeDialog.recommendIncomeList = [...result];
        },
        initBestowDistributorList() {
            const result = [];
            this.distributorLevelList.forEach(level => {
                let isExit = false;
                this.recommendIncomeDialog.bestowDistributorList.forEach(item => {
                    if (+item.distributorLevelId === +level.distributorLevelId) {
                        isExit = true;
                        item.levelName = level.levelName;
                        result.push({ ...item });
                    }
                });
                if (!isExit) {
                    result.push({
                        distributorLevelId: level.distributorLevelId,
                        levelName: level.levelName,
                    });
                }
            });
            this.recommendIncomeDialog.bestowDistributorList = [...result];
        },
        onRecommendIncomeEdit(row) {
            const { formModel } = this.recommendIncomeDialog;
            this.$api.Mb.DistributorLevel.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.formModelMerge(formModel, res);
                this.recommendIncomeDialog.recommendIncomeList = JSON.parse(res.recommendIncome || null) || [];
                this.recommendIncomeDialog.bestowDistributorList = JSON.parse(res.bestowDistributor || null) || [];

                this.$api.Mb.DistributorLevel.select().then(json2 => {
                    const res2 = json2.data.data;
                    res2.forEach(item => {
                        item.distributorLevelId = item.id;
                        item.id = null;
                    });
                    this.distributorLevelList = res2;
                    this.initRecommendIncomeList();
                    this.initBestowDistributorList();
                });
            });
            this.recommendIncomeDialog.isVisible = true;
        },
        onRecommendIncomeDialogConfirm() {
            const { recommendForm } = this.$refs;
            recommendForm.validate().then(() => {
                this.$api.Mb.DistributorLevel.save({
                    ...this.recommendIncomeDialog.formModel,
                    recommendIncome: JSON.stringify(this.recommendIncomeDialog.recommendIncomeList),
                    bestowDistributor: JSON.stringify(this.recommendIncomeDialog.bestowDistributorList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.recommendIncomeDialog.isVisible = false;
                });
            });
        },
        onRecommendIncomeDialogCancel() {
            const { recommendForm } = this.$refs;
            recommendForm.resetFields();
            const { formModel } = this.recommendIncomeDialog;
            formModel.id = '';
            this.recommendIncomeDialog.isVisible = false;
        },
        onIsDefaultInput(val, scope) {
            const { row } = scope;
            if (!row) return;
            this.$api.Mb.DistributorLevel.isDefault({
                id: row.id,
                isDefault: row.isDefault,
            })
                .then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                })
                .catch(() => {
                    this.$message({
                        message: '操作失败请重试！',
                        type: 'error',
                    });
                    row.isDefault = !row.isDefault;
                });
        },
    },
    created() {
        this.initShopDistributionIncomeMode();
        this.initShopStoreType();
    },
};
</script>

<style lang="scss">
</style>
