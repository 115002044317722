<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page class="distributor-subordinate-page">
        <!-- 表单区 -->

        <!-- 内容 -->
        <ECharts
            ref="charts"
            class="body echarts-demo"
            slot="body"
            v-loading="querying"
            :options="chartsOptions"
            @click="onChartsClick"
        >
            <!-- 操作 -->
            <!-- 表格 -->
            <!-- 分页 -->
        </ECharts>
        <!--<div v-else>无数据</div>-->
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'Distributor',
    mixins: [pagesMixin],
    data() {
        return {
            querying: false,
            subordinateList: [],
        };
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        subordinateMap() {
            return this.subordinateList.reduce((p, c) => {
                p[c.id] = c;
                return p;
            }, {});
        },
        subordinateData() {
            const res = [];
            const { subordinateMap } = this;
            this.subordinateList.forEach(item => {
                const { referenceId, levelNum } = item;
                if (!levelNum) {
                    res.push(item);
                } else {
                    const parent = subordinateMap[referenceId];
                    if (!parent) {
                        console.error('此节点数据有问题', item);
                    } else {
                        parent.children = parent.children || [];
                        const { children } = parent;
                        children.push(item);
                    }
                }
            });
            return res;
        },
        chartsOptions() {
            return {
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove',
                },
                series: [
                    {
                        type: 'tree',

                        // data: [data1],
                        data: this.subordinateData,

                        // top: '1%',
                        left: '10%',
                        // bottom: '1%',
                        right: '20%',

                        symbolSize: 30,

                        label: {
                            normal: {
                                position: 'right',
                                verticalAlign: 'middle',
                                align: 'left',
                                fontSize: 14,
                                formatter: '{name|{b}}\n{c}',
                                backgroundColor: '#dee2e6',
                                borderRadius: 5,
                                padding: [5, 10],
                                rich: {
                                    name: {
                                        fontSize: 18,
                                        color: '#000',
                                        // lineHeight: 10,
                                        // borderColor: '#449933',
                                        borderRadius: 4,
                                    },
                                },
                            },
                        },

                        leaves: {
                            label: {
                                normal: {
                                    position: 'right',
                                    verticalAlign: 'middle',
                                    align: 'left',
                                },
                            },
                        },

                        roam: true,
                        expandAndCollapse: false,
                        initialTreeDepth: -1,
                        animationDuration: 550,
                        animationDurationUpdate: 750,
                    },
                ],
            };
        },
    },
    methods: {
        /* -- 方法 -- */
        getDistributorSubordinateList() {
            this.querying = true;
            this.$api.Mb.Distributor.salesmanStat({
                id: this.id,
            })
                .then(json => {
                    const res = json.data.data;
                    this.subordinateList = res.map(item => ({
                        ...item,
                        name: item.distributorName,
                        value:
                            +item.levelNum !== 0
                                ? `+${item.distributorCountryCode}-${item.distributorTel}` +
                                  `\n个人订单数量：${item.totalOrderNum}` +
                                  `\n个人订单金额：${item.totalOrderAmount}` +
                                  `\n团队订单数量：${item.groupTotalOrderNum}` +
                                  `\n团队订单金额：${item.groupTotalOrderAmount}`
                                : `+${item.distributorCountryCode}-${item.distributorTel}`,
                        symbol: item.levelIconUrl ? `image://${item.levelIconUrl}` : '',
                    }));
                })
                .finally(() => {
                    this.querying = false;
                });
        },
        /* -- 事件 -- */
        onChartsClick($event, a, b) {
            console.log($event, a, b);
        },
    },
    created() {
        this.getDistributorSubordinateList();
    },
};
</script>

<style lang="scss" scoped>
.echarts-demo {
    width: 100%;
}
</style>
<style lang="scss">
.distributor-subordinate-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    > .header {
        flex: 0 0 auto;
    }
    > .body {
        flex: 1 1 1%;
    }
}
</style>
