<!-- Created by henian.xu on 2018/9/5. -->

<template>
    <page ref="page">
        <div
            class="second-header"
            slot="secondHeader"
        >
            <el-button
                size="small"
                type="primary"
                @click="onSave(false)"
            >
                保存
            </el-button>
            <!--           <el-button
                           size="small"
                           type="success"
                           @click="onSave(true)"
                       >保存并预览
                       </el-button>-->
            <el-button
                size="small"
                @click="onRestore"
            >
                还原初始状态
            </el-button>
        </div>

        <PageDecorator
            ref="pageDecorator"
            :json="decorationJson"
        />

        <el-dialog
            title="扫一扫"
            width="300px"
            :visible.sync="QRCodeDialog.visible"
        >
            <img
                :src="qrcodeUrl"
                width="100%"
            >
        </el-dialog>
    </page>
</template>

<script>
import QRCode from 'qrcode';

export default {
    name: 'DecorationEdit',
    data() {
        return {
            qrcodeUrl: '',
            saveFormModel: {},
            decorationJson: undefined,
            saveFormRules: {},
            QRCodeDialog: {
                visible: false,
            },
        };
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
    },
    methods: {
        onSave(preview) {
            this.$api.Mb.DistributorLevel.editDistributorLevel({
                id: this.id,
                distributeIncomeDesc: this.$refs.pageDecorator.getDecorationJson(),
            }).then(json => {
                const res = json.data;
                if (preview) {
                    console.log(res);
                    QRCode.toDataURL(
                        res.data.buyerUrl,
                        {
                            margin: 2,
                            scale: 30,
                            errorCorrectionLevel: 'H',
                        },
                        (error, url) => {
                            if (error) {
                                throw new Error(error);
                            }
                            this.qrcodeUrl = url;
                            this.QRCodeDialog.visible = true;
                        },
                    );
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                }
            });
        },
        onRestore() {
            this.$refs.pageDecorator.restoreInitial();
        },
    },
    created() {
        if (this.id) {
            this.$api.Mb.DistributorLevel.getDetail({ id: this.id }).then(json => {
                const res = json.data.data || {};
                this.decorationJson = res.distributeIncomeDesc || undefined;
            });
        }
    },
};
</script>

<style lang="scss">
.decoration-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    > .ctrl {
        /*position: fixed;*/
        /*right: 18px;*/
        /*width: 400px;*/
    }
}
</style>
