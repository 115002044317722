<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="statDate"
                    label="统计时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.statDateDay"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    type="index"
                    width="50"
                />
                <el-table-column
                    prop="orderTime"
                    label="统计时间"
                    min-width="100"
                />
                <el-table-column
                    prop="pv"
                    label="PV"
                    min-width="50"
                />
                <el-table-column
                    prop="uv"
                    label="UV"
                    min-width="50"
                />
                <el-table-column
                    prop="ip"
                    label="IP数"
                    min-width="50"
                />
                <el-table-column
                    prop="orderNum"
                    label="订单数"
                    min-width="50"
                />
                <el-table-column
                    label="订单金额"
                    min-width="50"
                >
                    <template slot-scope="scope">
                        {{ scope.row.orderAmount ? scope.row.orderAmount : '0.00' }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="buyerVisitLogDetail(scope.row)"
                        >
                            查看详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <el-dialog
            title="查看报表"
            center
            width="1200px"
            :visible.sync="eChartDialog.isVisible"
            @closed="onEChartDialogCancel"
        >
            <ECharts
                class="echarts-demo"
                :options="eChartDialog.option"
            />
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onEChartDialogCancel"
                >
                    关闭
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'BuyerVisitLogDetail',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                statDateDay: '', // 统计日期
                distributorBuyerId: '', // 统计日期
                type: '1',
            },
            // 表格数据
            tableData: [],
            eChartDialog: {
                // 对话框显示
                isVisible: false,
                option: {
                    tooltip: {
                        trigger: 'axis',
                        position(pt) {
                            return [pt[0], '10%'];
                        },
                    },
                    title: {
                        left: 'center',
                        text: '流量统计',
                    },
                    toolbox: {
                        feature: {
                            dataZoom: {
                                yAxisIndex: 'none',
                            },
                            restore: {},
                            saveAsImage: {},
                        },
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: [],
                    },
                    yAxis: {
                        type: 'value',
                        boundaryGap: [0, '100%'],
                    },
                    dataZoom: [
                        {
                            type: 'inside',
                            start: 0,
                            end: 100,
                        },
                        {
                            start: 0,
                            end: 10,
                            handleIcon:
                                'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                            handleSize: '80%',
                            handleStyle: {
                                color: '#fff',
                                shadowBlur: 3,
                                shadowColor: 'rgba(0, 0, 0, 0.6)',
                                shadowOffsetX: 2,
                                shadowOffsetY: 2,
                            },
                        },
                    ],
                    series: [
                        {
                            name: 'pv',
                            type: 'line',
                            smooth: true,
                            symbol: 'none',
                            sampling: 'average',
                            data: [],
                        },
                        {
                            name: 'uv',
                            type: 'line',
                            smooth: true,
                            symbol: 'none',
                            sampling: 'average',
                            data: [],
                        },
                        {
                            name: 'ip数',
                            type: 'line',
                            smooth: true,
                            symbol: 'none',
                            sampling: 'average',
                            data: [],
                        },
                        {
                            name: '订单数',
                            type: 'line',
                            smooth: true,
                            symbol: 'none',
                            sampling: 'average',
                            data: [],
                        },
                    ],
                },
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Stat.BuyerVisitLog.buyerVisitLogDetailList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        buyerVisitLogDetail(row) {
            this.$api.Stat.BuyerVisitLog.buyerVisitLogDetailList({
                ...this.queryFormModel,
                type: 0,
                statDate: row.day_code,
                pageSize: 100,
            }).then(json => {
                const res = json.data.data;

                this.eChartDialog.option.xAxis.data = [];
                this.eChartDialog.option.series[0].data = [];

                res.forEach(item => {
                    this.eChartDialog.option.xAxis.data.push(item.orderTime);
                    this.eChartDialog.option.series[0].data.push(item.pv || 0);
                    this.eChartDialog.option.series[1].data.push(item.uv || 0);
                    this.eChartDialog.option.series[2].data.push(item.ip || 0);
                    this.eChartDialog.option.series[3].data.push(item.orderNum || 0);
                });
            });
            this.eChartDialog.isVisible = true;
        },
        onEChartDialogCancel() {
            this.eChartDialog.isVisible = false;
        },
    },
    created() {
        this.queryFormModel.distributorBuyerId = this.$route.params.id;
        this.queryFormModel.statDateDay = [this.$utils.getSysMonthFirstDayDate(), this.$utils.getSysDayDate()];
    },
};
</script>


<style lang="scss">
.echarts-demo {
    width: 100%;
}
</style>
